
import { defineComponent, onMounted } from "vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import TransportsTable from "@/components/TransportsTable.vue";

export default defineComponent({
  name: "donors",
  components: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Bidones", ["Listado"]);
      MenuComponent.reinitialization();
    });
  },
});
